.UnitMenu {
  background-color: white;
  white-space: nowrap;
}

.UnitMenu-item {
  padding: 4px 10px;
}

.UnitMenu-item:hover {
  background-color: lightcyan;
}
