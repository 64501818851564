.GameOverModal {
  left: 20%;
  right: 20%;
  top: 40%;
  bottom: 40%;
  background-color: white;
  padding: 16px;
}

.GameOverModal > h1 {
  text-align: center;
}
