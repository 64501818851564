.EditorPanel {
  position: absolute;
  right: 0;
  top: 0;
  width: 250px;
  background-color: white;
  height: 100vh;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  max-height: 100vh;
  overflow-y: scroll;
}

.EditorPanel > section > header {
  background-color: #efefef;
  /* width: 100%; */
  padding: 8px;
  font-weight: bold;
}

.EditorPanel-dimensions {
  padding: 8px;
}

.EditorPanel-dimensions-item > span {
  width: 50px;
  margin-right: 8px;
  display: inline-block;
}

.EditorPanel-dimensions-item > input {
  width: 80px;
}

.EditorPanel-terrain {
  padding: 0 0 8px 8px;
}

.EditorPanel-terrain-list-header {
  font-weight: bold;
  padding: 8px 0;
}

.EditorPanel-terrain-list-item {
  margin: 0 2px 2px 0px;
  cursor: pointer;
}

.EditorPanel-buildings {
  margin: 8px;
}

.EditorPanel-buildings-list-header {
  font-weight: bold;
  padding: 8px 0;
}

.EditorPanel-buildings-list-item {
  margin: 0 2px 2px 0px;
  cursor: pointer;
}

.EditorPanel-units {
  margin: 8px;
}

.EditorPanel-units-item {
  margin: 2px;
  padding: 2px;
  cursor: pointer;
}

.EditorPanel-factions {
  padding: 8px;
}

.EditorPanel-save {
  padding: 8px;
}

.EditorPanel-save-textarea {
  resize: none;
}

.EditorPanel-save-validation {
  color: red;
}
