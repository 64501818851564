.Mission {
  position: absolute;
}

.Mission-swords {
  width: 32px;
  cursor: pointer;
}

.Mission-swords_active {
  animation: pulsate 1.5s ease-out;
  animation-iteration-count: infinite;
}

.Mission-box {
  position: absolute;
  background-color: white;
  left: 100%;
  padding: 8px;
  white-space: nowrap;
}

.Mission-box-title {
  font-size: 1.5rem;
  font-weight: bold;
}

@keyframes pulsate {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}
