.NewTurnModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.NewTurnModal-inner {
  background-color: white;
  padding: 16px;
}
